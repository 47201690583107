.circle-badge {
  position: absolute;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  right: 96px;
  top: 29vh;
  background: #c4b38b;
  transform: rotate(12deg);
  float: right;

  .center-text {
    color: #fff;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: max-content;
    text-align: center;

    h4 {
      font-size: initial;
    }

    &::before {
      content: '';
      border-top: 1px solid #fff;
      width: 25px;
      height: 2px;
      display: block;
      margin: 2.5px 36%;
    }

    &::after {
      content: '';
      border-bottom: 1px solid #fff;
      width: 25px;
      height: 2px;
      display: block;
      margin: 2.5px 36%;
    }
  }
}

.bottom-turtle-pattern {
  background-image: url('../../../../src/assets/images/TURTLE_Pattern.svg');
  height: 200px;
  background-size: 80%;
  background-position: 5% 70px;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .circle-badge {
    display: none;
  }

  .bottom-turtle-pattern {
    background-size: 80%;
    background-position: 5% 70px;
  }
}

@media (min-width: 600px) {
  .circle-badge {
    height: 115px;
    width: 115px;
    top: 6.5vh;
    right: 18%;

    .center-text h4 {
      font-size: inherit;
    }
  }

  .bottom-turtle-pattern {
    background-size: 60%;
    background-position: 5% 70px;
  }
}

@media (min-width: 768px) {
  .circle-badge {
    height: 115px;
    width: 115px;
    top: 19.5vh;
    right: 17%;

    .center-text h4 {
      font-size: inherit;
    }
  }

  .bottom-turtle-pattern {
    background-size: 55%;
    background-position: 5% 50px;
  }
}
@media (min-width: 992px) {
  .circle-badge {
    height: 140px;
    width: 140px;

    right: 96px;
    top: 29vh;

    .center-text h4 {
      font-size: inherit;
    }
  }

  .bottom-turtle-pattern {
    background-size: 55%;
    background-position: 5% 50px;
  }
}
