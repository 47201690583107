//
// menu.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  width: $leftbar-width;
  position: fixed;
  top: 0;

  span.logo-lg {
    display: block;
  }
  span.logo-sm {
    display: none;
  }

  &.logo-light {
    display: $logo-light-display;
  }
  &.logo-dark {
    display: $logo-dark-display;
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: $leftbar-width;
  overflow: hidden;
  padding: $topbar-height 12px 65px;
  min-height: 100vh;
}

// Left Side-menu (Default dark)
.leftside-menu {
  width: $leftbar-width;
  z-index: 10;
  background: $bg-leftbar;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: $topbar-height;
  box-shadow: $box-shadow;
}

// Side-nav
.side-nav {
  padding-left: 0;
  list-style-type: none;
  ul {
    list-style-type: none;
  }
  .side-nav-link {
    color: $menu-item;
    display: block;
    padding: 10px 30px;
    font-size: 0.82rem;
    position: relative;
    transition: all 0.4s;
    list-style: none;

    &:hover,
    &:focus,
    &:active {
      color: $menu-item-hover;
      text-decoration: none;
    }

    span {
      vertical-align: middle;
      display: inline-block;
      width: min-content;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 0;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  .menu-arrow {
    transition: transform 0.15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1.1rem;
    transform: translate(0, 0);
    &:before {
      content: '\F0142';
    }
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    > a[aria-expanded='true'] {
      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }

    &.menuitem-active {
      > a:not(.collapsed) {
        > span.menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-bold;
  }

  .menuitem-active {
    > a {
      color: $menu-item-active !important;
    }
  }
}

[dir='rtl'] .side-nav {
  .menu-arrow {
    &:before {
      content: '\F0141';
      display: none;
    }
  }
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  padding-left: 0;

  li {
    a {
      padding: 8px 30px 8px 65px;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all 0.4s;
      font-size: 0.75rem;
      &:focus,
      &:hover {
        color: $menu-item-hover;
      }

      .menu-arrow {
        line-height: 1.3rem;
      }
    }
    &.active {
      > a {
        color: $menu-item-active;
      }
    }
  }
}

.side-nav-third-level {
  li {
    a {
      padding: 8px 30px 8px 80px;
    }
  }
}

.side-nav-forth-level {
  li {
    a {
      padding: 8px 30px 8px 100px;
    }
  }
}

@include media-breakpoint-down(md) {
  body {
    overflow-x: hidden;
  }
  .leftside-menu {
    box-shadow: $box-shadow;
    left: -260px;
    z-index: 1002 !important;

    -webkit-transition: left 0.5s ease-out, background-color 0.5s ease-out;
    -moz-transition: left 0.5s ease-out, background-color 0.5s ease-out;
    -o-transition: left 0.5s ease-out, background-color 0.5s ease-out;
    transition: left 0.5s ease-out, background-color 0.5s ease-out;

    .close-icon {
      width: 40px;
      text-align: center;
    }
  }
  .sidebar-enable {
    .leftside-menu {
      left: 0px;
    }
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 65px 10px 65px;
  }
  .logo {
    span.logo-lg {
      display: block;
    }
    span.logo-sm {
      display: none;
    }
  }
}

/// Help-box
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: $help-box-light-bg;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.button-menu-mobile {
  .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all 0.5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }
  span {
    height: 2px;
    width: 100%;
    background-color: rgba($white, 0.8);
    display: block;
    margin-bottom: 5px;
    transition: transform 0.5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}
