.category-title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
}

.category-form {
  display: flex;
  flex-direction: column;
  background-color: white !important;
  padding-top: 10px;

  > .sub-profile-row {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -webkit-transform: translate3d(0, 0, 0);

    > .form-property {
      display: flex;
    }
  }
}
