.FileImageInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > .upload-section {
    border: 2px dashed darkgray;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: #e6eced;
    border-radius: 0.25rem;
    transition: border-color 0.6s linear;

    &.critical {
      border-color: $primaryColor2;
    }

    > img {
      height: -webkit-fill-available;
      padding: 5px;
      max-width: 300px;
      max-height: 90px;
    }

    > .right-icon-svg {
      padding: 35px 10px;
    }

    > .empty-logo {
      width: 90px;
    }

    > .updated-logo {
      background: white;
    }

    > .file-upload-input {
      flex: 1;
      padding: 5px 5px 5px 20px;
      height: -webkit-fill-available;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      > input[type='file'] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
      > .upload-details {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        font-size: 0.875rem;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        letter-spacing: 0.0625rem;
        color: black;

        > span {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.file-upload-button {
  background-color: $primaryColor1;
  color: white;
  border: none;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  font-size: 0.6875rem;
  line-height: 20px;
  cursor: pointer;
  padding: 15px 32px;
  text-transform: uppercase;
  width: fit-content;
  z-index: 10;
}

.file-upload-button:focus {
  outline: none;
}
.file-upload-button:hover {
  background-color: darken($primaryColor1, 5%);
}
.file-upload-button:active {
  transform: translateY(1px);
}
.file-upload-button:disabled {
  background-color: $secondaryColor3;
  color: $grey;
  cursor: not-allowed;
}
.file-upload-button:hover:disabled {
  background-color: darken($secondaryColor3, 10%);
}
