.FormLabel {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  width: -moz-available;
  line-height: 18px;
  color: lighten(black, 40%);
  font-size: 1rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0625rem;
  margin-top: 20px;
}
