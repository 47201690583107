//
// typography.scss
//

//H4 Title
h4.head-lg {
  font-size: 2.5rem;
  font-weight: bolder;
}

.head-xxlg {
  font-size: 4rem;
}

h5.head-lg {
  font-size: 27px;
  font-weight: 900;
  // line-height: 36px;
  width: 99%;
}

.text-sm {
  font-size: 24px;
}

.sub-heading-box {
  height: 2.5rem;
  overflow: hidden;
  @media (max-width: 1440px) {
    height: 3.8rem;
  }
}

.heading-box-height {
  height: 60px;
  overflow: hidden;
}
