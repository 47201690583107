.CompanyProfile {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  grid-auto-rows: max-content;
  margin: 20px;
  max-width: $max-width-layout;
  min-width: 250px;
  width: -webkit-fill-available;
  width: -moz-available;
}

@import '../components/scss/CompanyHeaderCard.scss';
