.company-details-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 30px;
  row-gap: 20px;

  > .header-logo {
    display: flex;
    align-items: center;
    width: 70%;
    max-width: 250px;
    > img {
      width: 100%;
      max-height: 200px;
    }
  }

  > .header-contact {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;

    > .company-name-value {
      line-height: 32px;
      padding: 0;
      color: $primaryColor1;
      font-size: 1.5rem;
      font-weight: 400;

      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.0625rem;
    }
  }

  > .company-like-icon {
    height: 40px;
    width: 60px;
    position: absolute;
    right: 10px;
    top: 10px;

    > img {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }
}
