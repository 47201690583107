@import './assets/scss/Saas.scss';

// colors
$primaryColor1: #0d5c75;
$primaryColor2: #f92755;
$secondaryColor3: #f6f6f6;

$grey: #888;
$darkgrey: #444444;

// responsive breakpoints
$break-large: 1300px;

// Layout and Grid
$max-width-layout: 1200px;
$min-width-sidenav: 250px;
$header-height: 70px;

// Custom styles

body {
  background-color: $off-white;
  background-image: $bg-pattern-img;
  background-repeat: no-repeat;
  background-size: 30vw;
  background-position: 80vw -15vh;
}

@media (max-width: 2200px) {
  body {
    background-size: 40vw;
    background-position: 70vw -15vh;
  }
}

@media (max-width: 1200px) {
  body {
    background-size: 60vw;
    background-position: 50vw -30vh;
  }
}

@media (max-width: 1028px) {
  body {
    background-size: 60vw;
    background-position: 50vw -30vh;
  }
}

@media (max-width: 767px) {
  body {
    background-size: 80vw;
    background-position: 55vw -15vh;
  }
}

@media (max-width: 375px) {
  body {
    background-size: 80vw;
    background-position: 55vw -5vh;
  }
}

.phone-number-input {
  input {
    border: none;
  }

  input:focus-visible {
    border: none;
    outline: none;
  }
}

.__typeform-embed-mobile-modal-open {
  z-index: 9999999999999 !important;
  position: absolute;
}

// Pages
@import './pages/Profile/scss/Profile';
@import './pages/PublicProfile/scss/PublicProfile.scss';
@import './pages/CompanyProfile/scss/CompanyProfile.scss';

// Components
@import './components/scss/index.scss';
@import './components/Form/scss/index.scss';
@import './components/Profile/scss/index.scss';
