//
// social.scss
//

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  text-align: center;
  border-radius: 50%;
  color: $primary;

  &:hover {
    color: $gray-600;
    border-color: $gray-600;
  }
}
