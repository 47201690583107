.PublicHeaderCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 30px;

  > .profile-logo {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    min-width: 150px;
    border: 5px #dcdee1 solid;
    overflow: hidden;
    background: #dcdee1;
  }

  > .profile-header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 0;
    row-gap: 10px;
    min-width: 300px;
    > .seafarer-name {
      font-size: 32px;
      font-weight: bold;
    }
    > .seafarer-title {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
