.ReadMoreDiv {
  overflow: hidden;
  line-height: 1rem;
  height: 4rem;
}
.ReadMoreDiv.collapsed {
  overflow: hidden;
}
.ReadMoreDiv.expanded {
  overflow: visible;
  height: auto;
}

.ReadMoreDiv-button {
  border: none;
  background-color: none;
  margin-top: 5px;
}
